@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

// GDDS Headlines
.gddsHeadlineH2 {
  h2 {
    font-size: 1.625rem;
    line-height: 1.23076923077;
    margin-bottom: 2.5rem;

    @include screen-sm {
      font-size: 1.75rem;
      line-height: 1.25;
    }

    @include screen-lg {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
  }
}

.hotelLabel {
  font-size: 0.75rem;
  line-height: 1rem;
  color: $gb_grey_650;
  padding-bottom: 0.25rem;
}

.buttonsSubmit {
  justify-content: right;
  display: flex;
  flex-direction: column;

  @include screen-sm {
    flex-direction: row;
  }

  & > button {
    @include screen-sm {
      margin-left: 1rem;
    }

    &:first-of-type {
      margin-top: 1rem;
      order: 1;

      @include screen-sm {
        margin-top: 0;
        order: 0;
      }
    }
  }

  .submitHint {
    flex-grow: 1;
    font-size: 0.75rem;
    margin-bottom: 2rem;

    @include screen-md {
      margin-bottom: 0;
    }
  }
}

.radioToggle {
  margin-top: 0.625rem;

  @include screen-lg {
    margin-bottom: 1.5rem;
  }
}

.checkboxToggle {
  @include screen-lg {
    margin-bottom: 1.5rem;
  }

  &#{&}#{&} a {
    align-items: center;

    span:first-child {
      padding-right: 0;
    }
  }
}

form .errorBorder input {
  border: 1px solid $gb_gdds_error;
}

.registrationForm {
  padding-bottom: 6.5625rem;
  border-bottom: none;

  @include screen-lg {
    padding-bottom: 7.5rem;
  }

  p {
    word-break: inherit;
  }

  strong {
    font-size: 1rem;
    font-weight: $font_weight_bold;
    line-height: 1.5;
  }

  hr {
    margin-bottom: 2.5rem;
    margin-top: 1rem;

    @include screen-lg {
      margin-bottom: 3rem;
    }
  }

  .commentWrapper {
    margin-bottom: 3rem;

    strong {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  :global {
    .form-item,
    .form-items {
      margin-bottom: 0;
    }

    .left {
      @include screen-sm {
        padding-right: 0.75rem;
      }
    }

    .right {
      @include screen-sm {
        padding-left: 0.75rem;
      }
    }

    .grid-x > div {
      margin-bottom: 1rem;
    }

    input[type='text'] {
      padding-left: 0.5rem;
    }

    button .c-tpp-area,
    .delete-button .c-tpp-area {
      width: auto;
      height: auto;
    }

    .delete-button {
      color: $gb_gdds_cl17;
      position: relative;
      top: -0.625rem;
      -webkit-appearance: none;

      span svg {
        fill: $gb_gdds_cl17;
      }
    }

    .headline-button-wrapper {
      justify-content: space-between;
    }

    .form-section {
      margin-bottom: 1.5rem;
    }
  }
}
