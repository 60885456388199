@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/base/fonts';

.gddsHeadlineH3 {
  font-weight: $font-weight-bold;
  text-transform: unset;
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin-bottom: 2.5rem;

  @include screen-sm {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
  }

  @include screen-md {
    font-size: 1.625rem;
    line-height: 2rem;
  }
}

.participantsTableWrapper {
  margin-bottom: 2.5rem;

  .gddsHeadlineH3 {
    margin-bottom: 1.25rem;
    margin-top: 2.5rem;
  }
}

.participantsTable {
  width: 100%;

  tr {
    th {
      line-break: auto;
      max-width: 18.75rem;
    }

    td {
      font-size: 0.875rem;
      line-break: auto;
      max-width: 18.75rem;
    }
  }
}

.accordionBodyWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 0.75rem;

  @include screen-sm {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

.accordionContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  @include screen-sm {
    margin-bottom: 1rem;
    margin-right: 2rem;
    width: 13rem;
  }

  &:nth-child(2) {
    @include screen-sm {
      width: 15rem;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  span:first-of-type {
    font-weight: $font-weight-medium;
  }
}
