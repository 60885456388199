@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/vendor/icons';

$gutter: 1.875rem;
$gutterGdds: 1.5rem;
$fontSize: 0.9375rem;
$lineHeight: 1.6;
$fontSizeGdds: 0.875rem;
$lineHeightGdds: 1.42857142857;

.contactModuleItem {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 1.25rem;
  border: 1px solid $gb_grey_200;

  // GDDS Variant
  :global(.contactModule--gdds) & {
    padding: 1.5rem;
  }

  &::before {
    @include web20-icon('campus-pin');
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 1.25rem;
  }

  @include screen-sm {
    max-width: 37.1875rem;

    // GDDS Variant
    :global(.contactModule--gdds) & {
      max-width: 36.75rem;
    }

    // detail page variant
    &.isDetail {
      max-width: 74%;
    }
  }

  @include screen-md {
    max-width: calc(50% - #{$gutter} / 2);

    // GDDS Variant
    :global(.contactModule--gdds) & {
      max-width: calc(50% - #{$gutterGdds} / 2);
    }
  }

  &:not(:last-child) {
    margin-right: $gutter;

    // GDDS Variant
    :global(.contactModule--gdds) & {
      margin-right: $gutterGdds;
    }
  }

  .contactName {
    display: block;
    max-width: 90%;
    margin-bottom: 0.9375rem;
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
    line-height: 1.22222;
    text-transform: none;

    // GDDS Variant
    :global(.contactModule--gdds) & {
      margin-bottom: 1rem;
    }
  }

  .contactAddress {
    margin-bottom: 0.9375rem;

    // GDDS Variant
    :global(.contactModule--gdds) & {
      margin-bottom: 1rem;
    }

    p {
      margin: 0;
      font-size: $fontSize;
      line-height: $lineHeight;

      // GDDS Variant
      :global(.contactModule--gdds) & {
        font-size: $fontSizeGdds;
        line-height: $lineHeightGdds;
      }
    }
  }

  &Bottom {
    display: flex;
    flex-direction: column;
  }

  .contactOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .contactMail,
    .contactPhone {
      max-width: 100%;
      position: relative;
      padding-left: 1.5rem;
      font-size: $fontSize;
      line-height: $lineHeight;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        position: absolute;
        left: 0;
        top: 0.375rem;
        padding: 0;
        font-size: $fontSize;
        color: $gb_black;
        text-decoration: none;
      }

      // GDDS Variant
      :global(.contactModule--gdds) & {
        font-size: $fontSizeGdds;
        line-height: $lineHeightGdds;
        font-weight: $font-weight-medium;
        color: var(--gb-brand-primary-dark);

        &::before {
          top: 0.125rem;
          font-size: 1rem;
        }
      }
    }

    .contactMail {
      margin-bottom: 0.3125rem;

      // GDDS Variant
      :global(.contactModule--gdds) & {
        margin-bottom: 0.5rem;
      }

      &::before {
        @include web20-icon('service-mail');
      }
    }

    .contactPhone {
      &::before {
        @include web20-icon('campus-phone');
      }
    }
  }

  .contactAvailability {
    margin-top: 1.5625rem;

    // GDDS Variant
    :global(.contactModule--gdds) & {
      margin-top: 1.5rem;
    }

    strong,
    p {
      font-size: 0.9375rem;
      line-height: 1.33333;

      // GDDS Variant
      :global(.contactModule--gdds) & {
        font-size: $fontSizeGdds;
        line-height: $lineHeightGdds;
      }
    }

    strong {
      display: block;
      font-weight: $font-weight-bold;
    }

    p {
      margin: 0;
    }
  }

  .contactModuleFurther {
    display: flex;
    padding-top: 1.25rem;

    a[type='button'] {
      appearance: none;
      text-decoration: none;
    }

    a::before,
    a span {
      color: $gb_black;
    }
  }

  // state
  // special behaviour of availability column,
  // depending on item count & breakpoint
  @mixin bottomInRow {
    &Bottom {
      flex-direction: row;
    }

    .contactOptions {
      min-width: 0;
      padding-right: 2rem;
      margin-bottom: 0;
    }

    .contactAvailability {
      flex-shrink: 0;
      max-width: 10.5rem;
      margin-left: auto;
      margin-top: -0.6875rem;
    }
  }

  :global(.item-count-1) & {
    @include screen-sm {
      @include bottomInRow();
    }
  }

  :global(.item-count-2) & {
    @include screen-md {
      @include bottomInRow();
    }
  }

  // GDDS Variant
  :global(.item-count-1.contactModule--gdds) & {
    @include screen-sm {
      .contactAvailability {
        margin-top: -1.25rem;
      }
    }
  }

  :global(.item-count-2.contactModule--gdds) & {
    @include screen-md {
      .contactAvailability {
        margin-top: -1.25rem;
      }
    }
  }

  // special style in slider context
  :global(.is-slider) & {
    max-width: none;
  }
}
