@import '../../../styles/base/variables';

.player {
  width: 100%;
  height: 100%;
  min-height: 672px;
}

.playerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.loadOverlay {
  background: $gb_white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 20;
}
